import { useAppDispatch, useAppSelector } from "hooks";
import { useState, useRef, useEffect } from "react"; // Import useRef and useEffect
import { GlobalIState } from "types/RootState";

import styles from "./MultiSelectDropdown.module.scss";
import { ArrowDownIcon } from "assets/icons/icons";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import {
  updateFiltersArray,
  getDefaultColour,
  getFilterValue,
} from "utils/utils";
import {
  fetchProductsBegin,
  getAppellationsFiltersBegin,
  getRegionsFilterBegin,
  setFilters,
} from "pages/Purchasing/actions";

import StorageService from "services/storage.service";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

interface Props {
  label: string;
  options: string[];
  type: string;
}

export default function MultiSelectDropdown(props: Props) {
  const { label, options, type } = props;
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { filters } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );

  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const countriesFilter: any = filters?.filter(
    (item) => item.filter === "Country"
  );
  const regionsFilter: any = filters?.filter(
    (item) => item.filter === "Region"
  );
  const [selectedOptions, setSelectedOptions] = useState<any[]>([
    ...(getFilterValue(type, filters) ?? []),
  ]);
  const dropdownRef = useRef(null);
  const [applyFilter, setApplyFilter] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchValueCookie = StorageService.getDataByKey("search");
  const [shouldFetchRegions, setShouldFetchRegions] = useState(false);
  const [shouldFetchAppellations, setShouldFetchAppellations] = useState(false);
  const [searchParams] = useSearchParams();
  const streamParam = searchParams.get("stream");

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCheckboxChange = (option) => {
    if (selectedOptions?.includes(option)) {
      setSelectedOptions(selectedOptions?.filter((item) => item !== option));
    } else {
      setSelectedOptions([...(selectedOptions ?? []), option]);
    }

    dispatch(
      setFilters({
        filter: type,
        value: selectedOptions?.includes(option)
          ? selectedOptions?.filter((item) => item !== option)
          : [...(selectedOptions ?? []), option],
      })
    );

    if (type === "Country") {
      setShouldFetchRegions(true);
    }

    if (type === "Region") {
      setShouldFetchAppellations(true);
    }

    if (!isMobile) {
      setApplyFilter(true);
    }
  };

  useEffect(() => {
    if (applyFilter) {
      setApplyFilter(false);
      StorageService.setKeyValue("products_page", 1);
      if (filters?.filter((item) => item?.value?.length >= 1)?.length === 0) {
        dispatch(
          fetchProductsBegin(
            streamParam,
            1,
            searchValueCookie ? searchValueCookie : "",
            [{}],
            getLanguageToken()
          )
        );
      } else {
        dispatch(
          fetchProductsBegin(
            streamParam,
            1,
            searchValueCookie ? searchValueCookie : "",
            updateFiltersArray(
              filters.filter((item) => item.value.length >= 1)
            ),
            getLanguageToken()
          )
        );
      }
    }

    // eslint-disable-next-line
  }, [filters, applyFilter]);

  useEffect(() => {
    if (type === "Country" && shouldFetchRegions) {
      if (countriesFilter[0] && countriesFilter[0]?.value?.length >= 1) {
        dispatch(
          getRegionsFilterBegin({
            countries: countriesFilter[0]?.value,
            lang: getLanguageToken(),
          })
        );
        setShouldFetchRegions(false);
      }
    }
  }, [shouldFetchRegions, filters]);

  useEffect(() => {
    if (type === "Region") {
      if (
        countriesFilter[0] &&
        countriesFilter[0]?.value?.length >= 1 &&
        regionsFilter[0] &&
        regionsFilter[0]?.value?.length >= 1
      ) {
        dispatch(
          getAppellationsFiltersBegin({
            countries: countriesFilter[0]?.value,
            regions: regionsFilter[0]?.value,
            lang: getLanguageToken(),
          })
        );
        setShouldFetchAppellations(false);
      }
    }
  }, [shouldFetchAppellations, filters]);

  useEffect(() => {
    setSelectedOptions(getFilterValue(type, filters));
  }, [filters]);

  return (
    <div ref={dropdownRef} className={styles.multiSelectDropdownContainer}>
      <div className={styles.dropdownLabel} onClick={handleToggleDropdown}>
        <span>
          {label}
          {selectedOptions?.length > 0 ? ` (${selectedOptions.length})` : null}
        </span>
        <ArrowDownIcon color={"#000"} />
      </div>

      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div className={styles.filters}>
            {options?.map((option) => (
              <Tooltip
                title={option?.length >= (isMobile ? 25 : 15) ? option : ""}
              >
                <label key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions?.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  {option?.length >= (isMobile ? 25 : 15) && type !== "Price"
                    ? option.substring(0, isMobile ? 25 : 15) + "..."
                    : option}
                </label>
              </Tooltip>
            ))}
          </div>
          <div
            className={styles.closeFilterBtn}
            onClick={() => handleToggleDropdown()}
          >
            <span>{translations("button.close")}</span>
          </div>
        </div>
      )}
    </div>
  );
}
