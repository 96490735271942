import React, { useEffect, useState } from "react";
import styles from "./Moments.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour, getOnlyNumbersFromString } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";

import MomentsQuestions from "assets/data/moments-questions.json";
import {
  BackButtonIcon,
  FirstQuestionStored,
  FiveQuestionsStored,
  FourthQuestionStored,
  NoQuestionsStored,
  SecondQuestionStored,
  ThirdQuestionStored,
} from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonComponent from "components/Button/Button.component";
import {
  clearAction,
  getTastePreferencesBegin,
  saveMomentsProfileBegin,
} from "../actions";
import { SAVE_MOMENTS_PROFILE_SUCCESS } from "../constants";
import { openToaster } from "App/actions";
import StorageService from "services/storage.service";

type Option = {
  text: string;
  nextStep?: string;
};

type Question = {
  id: string;
  question: string;
  options: Option[];
};

type Answer = {
  question: string;
  answer: string;
};

const Moments: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    tastePreferences,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [currentStep, setCurrentStep] = useState<string>("1");
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [hasValues, setHasValues] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const redoParam = searchParams.get("redo");
  const isLoggedIn = () => StorageService.getDataByKey("token");

  const steps: { [key: string]: Question } = MomentsQuestions.reduce(
    (acc, question) => {
      acc[question.id] = question;
      return acc;
    },
    {} as { [key: string]: Question }
  );

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
  };

  const handleShareProfile = () => {};

  const handleRedoMoments = () => {
    dispatch(clearAction());
    navigate("/taste-and-preferences/step1?redo=y");
  };

  const handleNextClick = () => {
    if (!selectedOption) return;

    const updatedAnswers = [
      ...answers,
      { question: steps[currentStep].question, answer: selectedOption.text },
    ];

    setAnswers(updatedAnswers);

    if (selectedOption.nextStep !== undefined) {
      const nextStepId = selectedOption.nextStep;
      if (steps[nextStepId]) {
        setCurrentStep(nextStepId);
      } else {
        console.error(`Error: nextStep ID ${nextStepId} not found.`);
      }
    }

    setSelectedOption(null);
    if (getOnlyNumbersFromString(currentStep) === 5) {
      setIsSubmit(true);
    }
  };

  const handleSubmit = () => {
    dispatch(saveMomentsProfileBegin(answers));
  };

  const handleSignup = () => {
    StorageService.setKeyValue("momentsToSubmit", answers);

    StorageService.setKeyValue(
      "lastRouteCookie",
      "/taste-and-preferences/profile?submit=y"
    );

    navigate("/signup");
  };

  useEffect(() => {
    if (actionName === SAVE_MOMENTS_PROFILE_SUCCESS) {
      dispatch(getTastePreferencesBegin());
      dispatch(
        openToaster(
          "SUCCESS",
          translations("label.your_moments_profile_stored_successfully")
        )
      );
      navigate("/taste-and-preferences/profile");
    }
  }, [actionName]);

  useEffect(() => {
    if (!redoParam && tastePreferences?.moments?.length > 0) {
      setHasValues(true);
    }

    if (isLoggedIn() && !tastePreferences) dispatch(getTastePreferencesBegin());
  }, [tastePreferences]);
  return (
    <div
      className={styles.momentsWrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div className={styles.backButtonContainer}>
          <div onClick={() => navigate(-1)} className="pointer">
            <BackButtonIcon color={"#000"} />
          </div>
        </div>

        {isSubmit || hasValues ? (
          <div></div>
        ) : (
          <div className={styles.rightLabel}>
            <span>
              {translations("label.step", {
                count: getOnlyNumbersFromString(currentStep),
              })}{" "}
              {translations("label.of")} 5
            </span>
          </div>
        )}
      </div>
      {isSubmit || hasValues ? (
        <div className={styles.submitAnswersContent}>
          <div>
            <h2>{translations("label.your_moments_profile")}</h2>
            {!hasValues ? (
              <span>{translations("label.moments_profile_subtitle")}</span>
            ) : null}
            <FiveQuestionsStored />
          </div>
          {hasValues ? (
            <div className={styles.bottomActions}>
              <ButtonComponent
                label={translations("label.share_profile")}
                class={"btn btn-primary"}
                disabled
                handleClick={handleShareProfile}
              />

              <div
                className={styles.redoButton}
                onClick={() => handleRedoMoments()}
              >
                <span>{translations("label.redo")}</span>
              </div>
            </div>
          ) : isLoggedIn() ? (
            <ButtonComponent
              label={translations("button.submit")}
              class={"btn btn-primary"}
              handleClick={handleSubmit}
            />
          ) : (
            <ButtonComponent
              label={translations("button.sign_up")}
              class={"btn btn-primary"}
              handleClick={handleSignup}
            />
          )}

          {!isLoggedIn() ? (
            <div className={styles.signupHints}>
              <span>{translations("label.signup_time_taken_hint")}</span>

              <div>
                <span>{translations("label.plus")}</span>
                <br />
                <span>
                  {translations("label.taste_preferences_signup_voucher")}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={styles.step}>
          <h2>{steps[currentStep].question}</h2>
          <div className={styles.answersCard}>
            <div className={styles.options}>
              {steps[currentStep].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className={`${styles.optionButton} ${
                    selectedOption?.text === option.text ? styles.selected : ""
                  }`}
                >
                  {option.text}
                </button>
              ))}
            </div>
            <div className={styles.nextButton}>
              <ButtonComponent
                label={translations("button.next")}
                class={"btn btn-primary"}
                disabled={!selectedOption}
                handleClick={handleNextClick}
              />
            </div>
          </div>

          {getOnlyNumbersFromString(currentStep) === 1 ? (
            <NoQuestionsStored />
          ) : getOnlyNumbersFromString(currentStep) === 2 ? (
            <FirstQuestionStored />
          ) : getOnlyNumbersFromString(currentStep) === 3 ? (
            <SecondQuestionStored />
          ) : getOnlyNumbersFromString(currentStep) === 4 ? (
            <ThirdQuestionStored />
          ) : getOnlyNumbersFromString(currentStep) === 5 ? (
            <FourthQuestionStored />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Moments;
