import React, { useEffect, useState } from "react";
import styles from "./HomeFilters.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate } from "react-router-dom";
import { CloseFirstTypeIcon, SearchIcon } from "assets/icons/icons";
import StreamMultiSelectDropdown from "components/StreamMultiSelectDropdown/StreamMultiSelectDropdown.component";
import StreamDateRangeFilter from "components/StreamDateRangeFilter/StreamDateRangeFilter.component";
import {
  clearAction,
  clearHomeFilters,
  getCardCategoriesBegin,
  getStreamFeedBegin,
  setHomeFilters,
} from "../actions";
import StorageService from "services/storage.service";
import { CLEAR_HOME_FILTERS, GET_STREAM_FEED_SUCCESS } from "../constants";
import { act } from "react-dom/test-utils";

const HomeFilters: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    cardCategories,
    filters,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [fromDate, setFromDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [untilDate, setUntilDate] = useState({ day: "", month: "", year: "" });
  const [search, setSearch] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);

  const handleDateChange = (from: any, until: any) => {
    setFromDate(from);
    setUntilDate(until);

    // dispatch(
    //   setHomeFilters({
    //     filter: "scheduleTime",
    //     value:
    //       until?.day !== "" || until?.month !== "" || until?.year !== ""
    //         ? [
    //             `${from?.year}-${from?.month}-${from?.day}`,
    //             `${from?.year}-${from?.month}-${from?.day}`,
    //           ]
    //         : [
    //             `${from?.year}-${from?.month}-${from?.day}`,
    //             `${until?.year}-${until?.month}-${until?.day}`,
    //           ],
    //   })
    // );
  };

  const isLoggedIn = () => StorageService.getDataByKey("token");
  const getLanguage = () => localStorage.getItem("lang");

  const handleResetFilters = () => {
    dispatch(clearHomeFilters());
    StorageService.deleteCookie("homeStreamSearchValue");
    StorageService.deleteCookie("homeFilters");
    setFromDate({
      day: "",
      month: "",
      year: "",
    });
    setUntilDate({
      day: "",
      month: "",
      year: "",
    });
    navigate("/home");
  };

  const handleSearch = (value: string) => {
    StorageService.setKeyValue("homeStreamSearchValue", value);
    setSearch(value);
  };

  const handleApplyFilters = () => {
    setApplyFilter(true);
    dispatch(
      setHomeFilters({
        filter: "scheduleTime",
        value:
          untilDate?.day !== "" ||
          untilDate?.month !== "" ||
          untilDate?.year !== ""
            ? [
                `${fromDate?.year}-${fromDate?.month}-${fromDate?.day}`,
                `${fromDate?.year}-${fromDate?.month}-${fromDate?.day}`,
              ]
            : [
                `${fromDate?.year}-${fromDate?.month}-${fromDate?.day}`,
                `${untilDate?.year}-${untilDate?.month}-${untilDate?.day}`,
              ],
      })
    );

    setTimeout(() => {
      dispatch(
        getStreamFeedBegin({
          isLoggedIn: isLoggedIn(),
          lang: getLanguage(),
          search: search,
          filters: filters,
        })
      );
    }, 0);
  };

  useEffect(() => {
    if (actionName === GET_STREAM_FEED_SUCCESS && applyFilter) {
      setApplyFilter(false);
      StorageService.deleteCookie("homeStreamSearchValues");
      dispatch(clearAction());
      navigate("/home");
    }
  }, [actionName]);

  useEffect(() => {
    if (!cardCategories) dispatch(getCardCategoriesBegin());
  }, [cardCategories]);

  useEffect(() => {
    return () => {
      StorageService.deleteCookie("homeStreamSearchValue");
    };
  }, []);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header} onClick={() => navigate("/home")}>
        <CloseFirstTypeIcon color={getDefaultColour(defaultColour)} />
      </div>

      <div className={styles.content}>
        <div>
          <h4>{translations("success.filters")}</h4>
          <StreamMultiSelectDropdown
            type="Category"
            label={translations("label.category")}
            options={cardCategories}
          />
          <StreamDateRangeFilter onDateChange={handleDateChange} />
          <div className={styles.lSearch}>
            <input
              type="text"
              className={styles.searchInp}
              placeholder={translations("label.search")}
              // onBlur={(e) => setIsLedgerSearching(false)}
              // onFocus={(e) => setIsLedgerSearching(true)}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <SearchIcon color="#000" />
          </div>
        </div>
        <div className={styles.actions}>
          <div onClick={() => handleResetFilters()}>
            <span>{translations("button.reset")}</span>
          </div>
          <div onClick={() => handleApplyFilters()}>
            <span>{translations("label.apply")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFilters;
