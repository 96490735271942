import React, { useEffect, useState } from "react";
import styles from "./TastePreferencesProfile.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  BackButtonIcon,
  CloseFirstTypeIcon,
  TasteProfileIlustration,
} from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import { getTastePreferencesBegin, saveMomentsProfileBegin } from "../actions";
import StorageService from "services/storage.service";
import { openToaster } from "App/actions";
import { SAVE_MOMENTS_PROFILE_SUCCESS } from "../constants";

const TastePreferencesProfile: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    tastePreferences,
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const submitMomentsParam = searchParams.get("submit");
  const momentsToSubmitCookie = StorageService.getDataByKey("momentsToSubmit");

  const handleShowFullProfile = () => {};

  useEffect(() => {
    if (!tastePreferences) {
      dispatch(getTastePreferencesBegin());
    }
  }, [tastePreferences]);

  useEffect(() => {
    if (submitMomentsParam === "y") {
      dispatch(saveMomentsProfileBegin(momentsToSubmitCookie));
    }
  }, [submitMomentsParam]);

  useEffect(() => {
    if (actionName === SAVE_MOMENTS_PROFILE_SUCCESS) {
      dispatch(getTastePreferencesBegin());
      searchParams.delete("submit");
      setSearchParams(searchParams);
      dispatch(
        openToaster(
          "SUCCESS",
          translations("label.your_moments_profile_stored_successfully")
        )
      );
    }
  }, [actionName]);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div className={styles.backButtonContainer}>
          <div onClick={() => navigate("/journal")} className="pointer">
            <CloseFirstTypeIcon color={"#000"} />
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <TasteProfileIlustration />

        <span>{translations("label.taste_profile.subtitle")}</span>

        <div className={styles.buttons}>
          {tastePreferences?.moments?.length === 0 ? (
            <ButtonComponent
              label={translations("label.enter_your_wine_moments")}
              class={"btn btn-primary"}
              handleClick={() => navigate("/taste-and-preferences/step1")}
            />
          ) : (
            <div onClick={() => navigate("/taste-and-preferences/moments")}>
              <span>
                {translations("label.taste_profile.your_wine_moments")}
              </span>
            </div>
          )}
          {tastePreferences?.flavours?.length === 0 ? (
            <ButtonComponent
              label={translations("label.enter_your_taste_preferences")}
              class={"btn btn-primary"}
              handleClick={() => navigate("/taste-and-preferences/step2")}
            />
          ) : (
            <div
              onClick={() =>
                navigate("/taste-and-preferences/general-taste/preview")
              }
            >
              <span>
                {translations("label.taste_profile.your_taste_preferences")}
              </span>
            </div>
          )}
        </div>

        <ButtonComponent
          label={translations("label.show_full_profile")}
          class={"btn btn-primary"}
          disabled
          handleClick={handleShowFullProfile}
        />
      </div>
    </div>
  );
};

export default TastePreferencesProfile;
