import React, { useEffect, useState } from "react";
import styles from "./EP1.module.scss";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import ButtonComponent from "components/Button/Button.component";

import SubmittedPoll from "./SubmittedPoll/SubmittedPoll.component";
import { getStreamFeedBegin, submitStreamPollBegin } from "pages/Home/actions";
import StorageService from "services/storage.service";

interface EP1CardProps {
  cardId: number;
  question: string;
  options: string[];
  buttonLabel: string;
  percentage: number;
  last_answer: string;
}

const EP1Card: React.FC<EP1CardProps> = ({
  cardId,
  question,
  options,
  buttonLabel,
  percentage,
  last_answer,
}) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    has_answered,
    filters,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const getLanguage = () => localStorage.getItem("lang");

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
  };

  const handleRetakePoll = () => {
    setSelectedOption("");
    setIsSubmitted(false);
  };

  const handleSubmit = () => {
    dispatch(
      submitStreamPollBegin({ card_id: cardId, answer: selectedOption })
    );
    dispatch(
      getStreamFeedBegin({
        isLoggedIn: isLoggedIn(),
        lang: getLanguage(),
        search: "",
        filters: filters,
      })
    );
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (has_answered) {
      setIsSubmitted(true);
    }
  }, [has_answered]);

  return isSubmitted ? (
    <SubmittedPoll
      question={question}
      label={last_answer}
      percentage={percentage}
      onRetake={() => handleRetakePoll()}
    />
  ) : (
    <div
      className={styles.cardContainer}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.question}>
        <span>{question}</span>
      </div>

      <div className={styles.optionsContainer}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`${styles.option} ${
              selectedOption === option ? styles.selected : ""
            }`}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </div>
        ))}
      </div>

      <ButtonComponent
        label={buttonLabel}
        class={"btn btn-primary"}
        disabled={!selectedOption}
        handleClick={handleSubmit}
      />
    </div>
  );
};

export default EP1Card;
