import React, { useEffect } from "react";
import styles from "./GeneralTasteProfilePreview.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AcidityConnectedLine,
  AromaConnectedLine,
  BackButtonIcon,
  BodyConnectedLine,
  FlavorConnectedLine,
} from "assets/icons/icons";
import TasteProfileOptions from "assets/data/taste-profile-options.json";
import StorageService from "services/storage.service";
import ButtonComponent from "components/Button/Button.component";
import {
  clearAction,
  getTastePreferencesBegin,
  saveGeneralTasteBegin,
} from "../actions";
import { SAVE_GENERAL_TASTE_SUCCESS } from "../constants";
import { openToaster } from "App/actions";

const GeneralTasteProfilePreview: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    tastePreferences,
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const submitParam = searchParams.get("submit");
  const GeneralTasteCookie = StorageService.getDataByKey("generalTasteData");

  const getValue = (index: number) =>
    (!submitParam && tastePreferences?.flavours?.length !== 0
      ? tastePreferences?.flavours[0]?.client_data
      : GeneralTasteCookie
    )?.find((item) => item?.question === TasteProfileOptions[index].title);

  const values = [
    {
      id: 1,
      label: "label.taste.body",
      value: getValue(0)?.answer,
      color: getValue(0)?.color,
      class: styles.bodyValue,
    },
    {
      id: 2,
      label: "label.taste.aroma",
      value: getValue(1)?.answer,
      color: getValue(1)?.color,
      class: styles.aromaValue,
    },
    {
      id: 3,
      label: "label.taste.flavor",
      value: getValue(2)?.answer,
      color: getValue(2)?.color,
      class: styles.flavorValue,
    },
    {
      id: 4,
      label: "label.taste.acidity",
      value: getValue(3)?.answer,
      color: getValue(3)?.color,
      class: styles.acidityValue,
    },
    {
      id: 5,
      label: "label.taste.sweetness",
      value: getValue(4)?.answer,
      color: getValue(4)?.color,
      class: styles.sweetnessValue,
    },
  ];

  const MiddleDotIcon = () => (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.26172" cy="4.89844" r="4.24023" fill="#fff" />
    </svg>
  );

  const handleShareProfile = () => {};

  const handleRedoGeneralTaste = () => {
    dispatch(clearAction());
    navigate("/taste-and-preferences/general-taste?redo=y");
  };

  const handleSubmit = () => {
    dispatch(saveGeneralTasteBegin(GeneralTasteCookie));
  };

  useEffect(() => {
    if (!tastePreferences) dispatch(getTastePreferencesBegin());
  }, [tastePreferences]);

  useEffect(() => {
    if (actionName === SAVE_GENERAL_TASTE_SUCCESS) {
      dispatch(getTastePreferencesBegin());
      dispatch(
        openToaster(
          "SUCCESS",
          translations("label.your_general_taste_profile_stored_successfully")
        )
      );
      navigate("/taste-and-preferences/profile");
    }
  }, [actionName]);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div className={styles.backButtonContainer}>
          <div
            onClick={() => navigate("/taste-and-preferences/profile")}
            className="pointer"
          >
            <BackButtonIcon color={"#000"} />
          </div>
        </div>
      </div>

      <h3>{translations("label.your_general_taste_profile")}</h3>

      <div className={styles.content}>
        {values.map((item, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.category}>
              <span>{translations(item.label)}</span>
            </div>

            <div className={item.class}>
              <div
                style={{ backgroundColor: item.color }}
                className={styles.dotBox}
              >
                {MiddleDotIcon()}
              </div>
            </div>
            {item.id === 1 ? (
              <div className={styles.bodySelected}>
                <span>{translations(item.value)}</span>
              </div>
            ) : item.id === 2 ? (
              <div className={styles.aromaSelected}>
                <span>{translations(item.value)}</span>
              </div>
            ) : item.id === 3 ? (
              <div
                style={{ left: item?.value?.length === 5 ? "52.5%" : "40%" }}
                className={styles.flavorSelected}
              >
                <span>{translations(item.value)}</span>
              </div>
            ) : item.id === 4 ? (
              <div className={styles.aciditySelected}>
                <span>{translations(item.value)}</span>
              </div>
            ) : item.id === 5 ? (
              <div className={styles.sweetnessSelected}>
                <span>{translations(item.value)}</span>
              </div>
            ) : null}
            {item.id === 1 ? (
              <div className={styles.bodyLine}>
                <BodyConnectedLine color={`${item.color}95`} />
              </div>
            ) : item.id === 2 ? (
              <div className={styles.aromaLine}>
                <AromaConnectedLine color={`${item.color}95`} />
              </div>
            ) : item.id === 3 ? (
              <div className={styles.flavorLine}>
                <FlavorConnectedLine color={`${item.color}95`} />
              </div>
            ) : item.id === 4 ? (
              <div className={styles.acidityLine}>
                <AcidityConnectedLine color={`${item.color}95`} />
              </div>
            ) : null}
          </div>
        ))}

        {submitParam === "y" ? (
          <div className={styles.bottomActions}>
            <ButtonComponent
              label={translations("button.submit")}
              class={"btn btn-primary"}
              handleClick={handleSubmit}
            />
          </div>
        ) : (
          <div className={styles.bottomActions}>
            <ButtonComponent
              label={translations("label.share_profile")}
              class={"btn btn-primary"}
              disabled
              handleClick={handleShareProfile}
            />

            <div onClick={() => handleRedoGeneralTaste()}>
              <span>{translations("label.redo")}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralTasteProfilePreview;
