import { useEffect, useRef, useState } from "react";
import styles from "./StreamDateRangeFilter.module.scss";
import { ArrowDownIcon } from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useAppDispatch, useAppSelector } from "hooks";
import { setHomeFilters } from "pages/Home/actions";
import StorageService from "services/storage.service";
import { getFilterValue } from "utils/utils";
import { GlobalIState } from "types/RootState";
import dayjs from "dayjs";
import { openToaster } from "App/actions";

type DateValue = { day: string; month: string; year: string };

type DateRangeProps = {
  onDateChange: (from: DateValue, until: DateValue) => void;
};

export default function StreamDateRangeFilter({
  onDateChange,
}: DateRangeProps) {
  const { filters } = useAppSelector(
    (state: GlobalIState) => state.HomeFeedReducer
  );
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const homeFiltersCookie = () =>
    StorageService.getDataByKey("homeFilters")?.find;

  const [fromDate, setFromDate] = useState<DateValue>({
    day: "",
    month: "",
    year: "",
  });
  const [untilDate, setUntilDate] = useState<DateValue>({
    day: "",
    month: "",
    year: "",
  });
  const cookieDateStored = getFilterValue("scheduleTime", filters);

  const handleChange = (
    field: keyof DateValue,
    newValue: string,
    type: "from" | "until"
  ) => {
    const sanitizedValue = newValue.replace(/\D/g, "");

    const maxLength = field === "year" ? 4 : 2;
    const finalValue = sanitizedValue.slice(0, maxLength);

    const newDate =
      type === "from"
        ? { ...fromDate, [field]: finalValue }
        : { ...untilDate, [field]: finalValue };

    if (type === "from") setFromDate(newDate);
    else setUntilDate(newDate);

    onDateChange(
      type === "from" ? newDate : fromDate,
      type === "until" ? newDate : untilDate
    );
  };

  const handleResetFilter = () => {
    setFromDate({ day: "", month: "", year: "" });
    setUntilDate({ day: "", month: "", year: "" });

    onDateChange(
      { day: "", month: "", year: "" },
      { day: "", month: "", year: "" }
    );

    dispatch(
      setHomeFilters({
        filter: "scheduleTime",
        value: [],
      })
    );

    setIsDropdownOpen(false);
  };

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleApplyFilter = () => {
    if (
      !fromDate.day &&
      !fromDate.month &&
      !fromDate.year &&
      !untilDate.day &&
      !untilDate.month &&
      !untilDate.year
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.please_fill_date_fields_properly")
        )
      );
      return;
    }
    return;
    dispatch(
      setHomeFilters({
        filter: "scheduleTime",
        value:
          untilDate?.day === "" ||
          untilDate?.month === "" ||
          untilDate?.year === ""
            ? [
                `${fromDate?.year}-${fromDate?.month}-${fromDate?.day}`,
                `${fromDate?.year}-${fromDate?.month}-${fromDate?.day}`,
              ]
            : [
                `${fromDate?.year}-${fromDate?.month}-${fromDate?.day}`,
                `${untilDate?.year}-${untilDate?.month}-${untilDate?.day}`,
              ],
      })
    );
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !(dropdownRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (cookieDateStored[0] && cookieDateStored[0] !== "--") {
      setFromDate({
        day: dayjs(cookieDateStored[0]).get("date").toString(),
        month: dayjs(cookieDateStored[0])
          .add(1, "month")
          .get("month")
          .toString(),
        year: dayjs(cookieDateStored[0]).get("year").toString(),
      });
    }

    if (cookieDateStored[1] && cookieDateStored[1] !== "--") {
      setUntilDate({
        day: dayjs(cookieDateStored[1]).get("date").toString(),
        month: dayjs(cookieDateStored[1])
          .add(1, "month")
          .get("month")
          .toString(),
        year: dayjs(cookieDateStored[1]).get("year").toString(),
      });
    }
  }, [filters, cookieDateStored]);

  return (
    <div ref={dropdownRef} className={styles.multiSelectDropdownContainer}>
      <div
        className={styles.dropdownLabel}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span>{translations("label.date")}</span>
        <ArrowDownIcon color={"#000"} />
      </div>

      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div>
            <div className={styles.dateRanges}>
              <div className={styles.dateLabel}>
                {translations("label.from")}
              </div>
              <div className={styles.dateFields}>
                <input
                  type="tel"
                  maxLength={2}
                  placeholder="dd"
                  value={fromDate.day}
                  onChange={(e) => handleChange("day", e.target.value, "from")}
                  className={styles.dateField}
                />
                <input
                  type="tel"
                  maxLength={2}
                  placeholder="mm"
                  value={fromDate.month}
                  onChange={(e) =>
                    handleChange("month", e.target.value, "from")
                  }
                  className={styles.dateField}
                />
                <input
                  type="tel"
                  maxLength={4}
                  placeholder="yyyy"
                  value={fromDate.year}
                  onChange={(e) => handleChange("year", e.target.value, "from")}
                  className={styles.dateField}
                />
              </div>
            </div>

            <div className={styles.dateRanges}>
              <div className={styles.dateLabel}>
                {translations("label.until")}
              </div>
              <div className={styles.dateFields}>
                <input
                  type="tel"
                  maxLength={2}
                  placeholder="dd"
                  value={untilDate.day}
                  onChange={(e) => handleChange("day", e.target.value, "until")}
                  className={styles.dateField}
                />
                <input
                  type="tel"
                  maxLength={2}
                  placeholder="mm"
                  value={untilDate.month}
                  onChange={(e) =>
                    handleChange("month", e.target.value, "until")
                  }
                  className={styles.dateField}
                />
                <input
                  type="tel"
                  maxLength={4}
                  placeholder="yyyy"
                  value={untilDate.year}
                  onChange={(e) =>
                    handleChange("year", e.target.value, "until")
                  }
                  className={styles.dateField}
                />
              </div>
            </div>
            <div
              className={styles.resetFilterBtn}
              onClick={() => handleResetFilter()}
            >
              <span>{translations("button.reset")}</span>
            </div>
          </div>

          {isMobile ? (
            <div
              className={styles.button}
              onClick={() => handleToggleDropdown()}
            >
              <span>{translations("button.close")}</span>
            </div>
          ) : (
            <div className={styles.button} onClick={() => handleApplyFilter()}>
              <span>{translations("label.apply")}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
