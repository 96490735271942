import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour, modifyHomeFiltersForDate } from "utils/utils";

import styles from "./Home.module.scss";
import SP1Card from "components/StreamCards/SP1/SP1.component";
import NP1Card from "components/StreamCards/NP1/NP1.component";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PP1Card from "components/StreamCards/PP1/PP1.component";
import {
  DinoNoBgIcon,
  GamificationIcon,
  MembershipIcon,
  NotificationsIcon,
  PrivateLedgerIcon,
  ProfileIcon,
  WineBottleStreamIcon,
} from "assets/icons/icons";
import EP1Card from "components/StreamCards/EP1/EP1.component";
import LP1Card from "components/StreamCards/LP1/LP1.component";
import SP3Card from "components/StreamCards/SP3/SP3.component";

import { useEffect } from "react";
import { getCardCategoriesBegin, getStreamFeedBegin } from "./actions";
import { IStreamFeedItem } from "types/reducers/homefeed.reducer.type";
import { useNavigate } from "react-router-dom";

import advancedFormat from "dayjs/plugin/advancedFormat.js";
import StorageService from "services/storage.service";
import { getClientBegin } from "pages/Profile/actions";
import Journal from "pages/PersonalLedger/Journal.page";
import SP1LCard from "components/StreamCards/SP1L/SP1L.component";
import LP1LCard from "components/StreamCards/LP1L/LP1L.component";
import { useTranslation } from "react-i18next";
import DP1Card from "components/StreamCards/DP1/DP1.component";
import { POPULATE_HOME_FILTERS } from "./constants";

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

function Home() {
  const { client, defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    streamFeed,
    filters,
    cardCategories,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const getLanguage = () => localStorage.getItem("lang");
  const { t: translations } = useTranslation();
  const homeStreamSearchValueCookie = () =>
    StorageService.getDataByKey("homeStreamSearchValue");
  const productFiltersCookie = () => StorageService.getDataByKey("homeFilters");

  const Seperator = ({ icon, datetime }) => {
    const getIcon = () => {
      switch (icon) {
        case "Notifications":
          return <NotificationsIcon color="#fff" />;
          break;
        case "Chats":
          return <DinoNoBgIcon color="#fff" />;
        case "Purchasing":
          return <WineBottleStreamIcon color="#fff" />;
          break;
        case "Games":
          return <GamificationIcon color="#fff" />;
          break;
        case "Control Centre":
        case "Profile & Wallet":
          return <ProfileIcon color="#fff" />;
          break;
        case "Ratings & Tastings":
          return <PrivateLedgerIcon color="#fff" />;
          break;
        case "Membership":
          return <MembershipIcon color="#fff" />;
        default:
          return <DinoNoBgIcon color="#fff" />;
          break;
      }
    };

    return (
      <div className={styles.seperator}>
        {getIcon()}
        <span>{dayjs(datetime).format("HH:mm | Do MMM. YYYY")}</span>
      </div>
    );
  };

  const renderElementByType = (item: IStreamFeedItem, index: number) => {
    const {
      // cardType: { short_type },
      contentCategory: { category_name },
    } = item;
    const short_type = item?.cardType?.short_type ?? "";

    const imgUrl =
      item?.images?.length > 0
        ? isMobile
          ? item?.images[0]?.mobile_image_url
          : item?.images[0]?.image_url
        : "";

    switch (short_type ?? "") {
      case "SP1":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <SP1Card item={item} image={imgUrl} />
          </div>
        );

      case "SP1L":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <SP1LCard item={item} image={imgUrl} />
          </div>
        );

      case "LP1":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <LP1Card item={item} image={imgUrl} />
          </div>
        );

      case "LP1L":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <LP1LCard item={item} image={imgUrl} />
          </div>
        );
      case "NP1":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <NP1Card item={item} />
          </div>
        );
      case "PP1":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <PP1Card
              title={item?.title}
              description={item?.content}
              images={item.images}
            />
          </div>
        );
      case "SP3":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <SP3Card item={item} image={imgUrl} />
          </div>
        );
      case "EP1":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <EP1Card
              cardId={item?.id}
              question={item?.title}
              options={[item?.subtitle, item?.second_subtitle]}
              percentage={item?.percentage}
              last_answer={item?.last_answer}
              buttonLabel={translations("button.submit")}
            />
          </div>
        );
      case "DP1":
        return (
          <div key={index} className={styles.feedItem}>
            <Seperator icon={category_name} datetime={item?.schedule_time} />
            <DP1Card
              item={item}
              question={item?.question}
              answer={item?.answer}
            />
          </div>
        );
      default:
        return (
          <div key={index} style={{ padding: "1rem" }}>
            <h4>Default Card</h4>
            <p>{item.title}</p>
          </div>
        );
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     getStreamFeedBegin({
  //       isLoggedIn: isLoggedIn(),
  //       lang: getLanguage(),
  //       search: homeStreamSearchValueCookie()
  //         ? homeStreamSearchValueCookie()
  //         : "",
  //       filters: modifyHomeFiltersForDate(filters)?.filters,
  //     })
  //   );

  //   if (!cardCategories) dispatch(getCardCategoriesBegin());
  // }, [, filters, cardCategories]);

  useEffect(() => {
    dispatch(
      getStreamFeedBegin({
        isLoggedIn: isLoggedIn(),
        lang: getLanguage(),
        search: homeStreamSearchValueCookie()
          ? homeStreamSearchValueCookie()
          : "",
        filters: modifyHomeFiltersForDate(filters)?.filters,
      })
    );
    if (!cardCategories) dispatch(getCardCategoriesBegin());

    return () => {
      StorageService.deleteCookie("homeStreamSearchValue");
    };
  }, [filters]);

  useEffect(() => {
    if (isLoggedIn() && !client) {
      dispatch(getClientBegin());
    }
  }, [client]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.wrapper}
    >
      {[...streamFeed]
        ?.sort(
          (a, b) =>
            new Date(b.schedule_time).getTime() -
            new Date(a.schedule_time).getTime()
        )
        ?.map((item, index) => renderElementByType(item, index))}
    </div>
  );
}

export default Home;
