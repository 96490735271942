import React, { useEffect, useState } from "react";
import styles from "./TasteAndPreference.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate } from "react-router-dom";
import {
  BackButtonIcon,
  CloseIconWhite,
  TasteAndPreferencesDesktopIlustration,
  TasteAndPreferencesIlustration,
} from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { getTastePreferencesBegin } from "../actions";

const TasteAndPreferences: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { tastePreferences } = useAppSelector(
    (state: GlobalIState) => state.HomeFeedReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const handleContinue = () => {
    if (tastePreferences?.moments?.length === 0) {
      navigate("/taste-and-preferences/step1");
    } else if (
      tastePreferences?.moments?.length !== 0 &&
      tastePreferences?.flavours?.length === 0
    ) {
      navigate("/taste-and-preferences/step2");
    } else if (
      tastePreferences?.moments?.length !== 0 ||
      tastePreferences?.flavours?.length !== 0
    )
      navigate("/taste-and-preferences/profile");
  };

  useEffect(() => {
    if (!tastePreferences) dispatch(getTastePreferencesBegin());
  }, [tastePreferences]);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      {isMobile ? (
        <div className={styles.card}>
          <div className={styles.header} onClick={() => navigate("/journal")}>
            <CloseIconWhite />
          </div>

          <TasteAndPreferencesIlustration />

          <div className={styles.content}>
            <div>
              <h3>{translations("label.taste_preferences.title")}</h3>
              <span>{translations("label.taste_preferences.subtitle")}</span>
              <h3>{translations("label.taste_preferences.step1")}</h3>
              <h3>{translations("label.taste_preferences.step2")}</h3>
            </div>

            <ButtonComponent
              label={translations("label.continue")}
              class={"btn btn-primary"}
              handleClick={handleContinue}
            />
          </div>
        </div>
      ) : (
        <div className={styles.card}>
          <div className={styles.header} onClick={() => navigate("/journal")}>
            <CloseIconWhite />
          </div>
          <div className={styles.desktopCard}>
            <div>
              <div>
                {" "}
                <h3>{translations("label.taste_preferences.title")}</h3>
                <span>{translations("label.taste_preferences.subtitle")}</span>
                <h3>{translations("label.taste_preferences.step1")}</h3>
                <h3>{translations("label.taste_preferences.step2")}</h3>
              </div>
            </div>
            <div>
              <TasteAndPreferencesDesktopIlustration />
            </div>
          </div>

          <div className={styles.mainButton}>
            {" "}
            <ButtonComponent
              label={translations("label.continue")}
              class={"btn btn-primary"}
              handleClick={handleContinue}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TasteAndPreferences;
