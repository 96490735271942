import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import styles from "./AskDino.module.scss";
import {
  AtHomeIcon,
  AtRestoIcon,
  CloseNoBgIcon,
  DinoIcon,
  LeftArrowIcon,
  MeSmartIcon,
  MeTime,
  OnTheRoadIcon,
} from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { callAIQuestionBegin } from "../actions";
import LoaderBounce from "components/Loader/bounce/LoaderBounce.component";
import { CALL_AI_ASISSTANT_SUCCESS } from "../constants";
import StorageService from "services/storage.service";
import * as dinoGeneratingAnimation from "assets/animations/dino-generating.json";
import Lottie from "react-lottie";

import AsisstantCommonTopicsJson from "assets/data/asisstant-common-topics.json";

function AskDino() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
    asisstantAnswer,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [question, setQuestion] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("label.at_home");
  const [selectedCategoryData, setSelectedCategoryData] = useState(
    AsisstantCommonTopicsJson.categories.find(
      (category) => category.name === selectedCategory
    )
  );

  const callAIWithCommonTopic = (topic: string) => {
    setQuestion(topic);

    setTimeout(() => {
      handleAskDinoAI(topic);
    }, 100);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dinoGeneratingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    renderer: "svg",
  };

  const getCategoryIcon = (type: string) => {
    switch (type) {
      case "label.at_home":
        return <AtHomeIcon />;
        break;
      case "label.at_resto":
        return <AtRestoIcon />;
        break;
      case "label.on_the_road":
        return <OnTheRoadIcon />;
        break;
      case "label.me_smart":
        return <MeSmartIcon />;
        break;
      case "label.me_time":
        return <MeTime />;
        break;
      default:
        return <AtHomeIcon />;
        break;
    }
  };

  const handleAskDinoAI = async (topic?: string) => {
    let questionToAI = topic ? topic : question;

    dispatch(callAIQuestionBegin(questionToAI));
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
    setSelectedCategoryData(
      AsisstantCommonTopicsJson.categories.find(
        (item) => item.name === category
      )
    );
  };

  useEffect(() => {
    if (actionName === CALL_AI_ASISSTANT_SUCCESS && asisstantAnswer !== "") {
      StorageService.setKeyValue("dinoChatThread", {
        question: question,
        answer: asisstantAnswer,
      });
      navigate("/chat-history", {
        state: {
          question: question,
          answer: asisstantAnswer,
        },
      });
    }
  }, [actionName]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.wrapper}
    >
      {isExpanded ? (
        <div
          className={styles.header}
          onClick={() => {
            setQuestion("");
            setIsExpanded(false);
          }}
        >
          <LeftArrowIcon />
        </div>
      ) : (
        <div className={styles.header} onClick={() => navigate(-1)}>
          <CloseNoBgIcon />
        </div>
      )}

      {(isMobile && !isExpanded) || !isMobile ? (
        <div className={styles.title}>
          <div className={styles.helpTitle}>
            <span>
              {translations("label.hi")} {client?.firstname}
            </span>
            <br />
            <span>{translations("label.how_can_i_help?")}</span>
          </div>
          <div></div>
        </div>
      ) : null}
      <div className={styles.content}>
        <div className={styles.leftPart}>
          <div className={styles.textBox}>
            <textarea
              placeholder={translations("label.type_here_or_select_below")}
              style={
                isMobile
                  ? {
                      height: isExpanded ? 280 : 60,
                      border: isExpanded ? "1px solid #000" : "none",
                      boxShadow: !isExpanded
                        ? "0px 0px 12.57034px 0px rgba(0, 0, 0, 0.12)"
                        : "",
                    }
                  : { height: 200 }
              }
              value={question}
              // onBlur={() =>
              //   question?.length === 0 ? setIsExpanded(false) : null
              // }
              onFocus={() => setIsExpanded(true)}
              onChange={(e) => setQuestion(e.target.value)}
            />
            {isExpanded ? (
              <div
                className={styles.clearChatIcon}
                onClick={() => setQuestion("")}
              >
                <span>X</span>
              </div>
            ) : null}
          </div>

          {!isMobile ? (
            <>
              {isLoading ? (
                <div className={styles.generatingAnswerButton}>
                  <LoaderBounce />
                </div>
              ) : (
                <div
                  className={styles.button}
                  onClick={() => handleAskDinoAI()}
                >
                  <Lottie options={defaultOptions} />
                  <span>{translations("label.ask_dino")}</span>
                </div>
              )}
            </>
          ) : null}
        </div>
        {(isMobile && !isExpanded) || !isMobile ? (
          <div className={styles.rightPart}>
            <div className={styles.tabs}>
              {AsisstantCommonTopicsJson.categories.map((category) => (
                <div
                  key={category.name}
                  className={styles.tab}
                  style={{
                    backgroundColor:
                      selectedCategory === category?.name ? "#c1c6d5" : "#fff",
                  }}
                  onClick={() => handleCategoryChange(category.name)}
                >
                  {getCategoryIcon(category.name)}
                  <span>
                    {translations(category.name)?.length >= 10
                      ? translations(category.name)?.slice(0, 10) + "..."
                      : translations(category.name)}
                  </span>
                </div>
              ))}
            </div>
            <div className={styles.tabContent}>
              {selectedCategoryData.questions.map((question, index) => (
                <div
                  onClick={() => {
                    setIsExpanded(true);
                    setQuestion(translations(question.display_text));
                  }}
                >
                  <span>{translations(question.short_link)}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>

      {!isMobile ? null : (
        <>
          {isLoading ? (
            <div className={styles.generatingAnswerButton}>
              <LoaderBounce />
            </div>
          ) : (
            <div className={styles.button} onClick={() => handleAskDinoAI()}>
              <Lottie options={defaultOptions} />
              <span>{translations("label.ask_dino")}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AskDino;
