import {
  checkPathsToShowPageNameNextToBackButton,
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
  getPageName,
  getTotalPrice,
  isMainPage,
  showCloseButton,
} from "utils/utils";
import styles from "./MobileHeader.module.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import StorageService from "services/storage.service";
import { useTranslation } from "react-i18next";
import {
  BackButtonIcon,
  CloseFirstTypeIcon,
  FiltersIcon,
  HamburgerIcon,
  HowItWorksIcon,
  JournalSearchIcon,
  LoginIcon,
  LogoutIcon,
} from "assets/icons/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import {
  closeModal,
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import { logOut } from "pages/Authentication/actions";
import { useEffect, useState } from "react";
import { clearCartItems } from "pages/Purchasing/actions";

interface Props {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (value: boolean) => void;
  setShowPurchasingFilters: (value: boolean) => void;
}

function MobileHeader(props: Props) {
  const { isMobileMenuOpen, setIsMobileMenuOpen, setShowPurchasingFilters } =
    props;

  const { leftPartShownItem, previousLeftPartShownItem } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const { client, defaultColour, pending_delivery_balance } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { cartItems } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const { isOpen } = useAppSelector(
    (state: GlobalIState) => state.modalReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const pathname = location.pathname;
  const { t: translations } = useTranslation();
  const isMainPageCheck = isMainPage(pathname);
  const showPageName = checkPathsToShowPageNameNextToBackButton(pathname);
  const [showCloseButtonCheck, setShowCloseButtonCheck] = useState(
    showCloseButton(pathname)
  );
  const paymentStatusParam = searchParams.get("payment_status");

  const isLoggedIn = () => StorageService.getDataByKey("token");
  const getFormattedValue = (value: number | string) => {
    return isLoggedIn()
      ? value?.toString()?.length <= 3
        ? Number(value)?.toFixed(2)
        : formatPrice(Number(value).toFixed(2)?.toString())
      : "0";
  };
  let pendingDeliveryBalance = formatPrice(
    pending_delivery_balance?.toFixed(2)?.toString()
  );
  const totalPrice = Number(getTotalPrice(cartItems)).toFixed(2);
  const deliveryPrice = StorageService.getDataByKey("deliveryPriceForHeader");
  const [showLedgerSearch, setShowLedgerSearch] = useState(false);
  let showSearchParam = searchParams.get("search");
  const orderTypeCookie = () => StorageService.getDataByKey("orderType");

  const totalPriceFormatted =
    getTotalPrice(cartItems) >= 149
      ? totalPrice
      : Number(getTotalPrice(cartItems) + deliveryPrice).toFixed(2);

  const availableBalance = isLoggedIn()
    ? getTotalPrice(cartItems) !== 0
      ? totalPriceFormatted
      : pending_delivery_balance === 0
      ? 0.0
      : pendingDeliveryBalance
    : 0.0;

  let medalsValue = isLoggedIn()
    ? formatPrice(client?.medals_balance.toString())
    : "0";

  let stakedValue = getFormattedValue(client?.staked_balance);

  const getPrevRoute = () => {
    return localStorage.getItem("prevRoute");
  };

  const handleBackButton = (pathname: string) => {
    if (isOpen) {
      dispatch(closeModal());
    } else
      switch (pathname) {
        case "/events":
          if (getPrevRoute() === "/events/registered") {
            localStorage.removeItem("prevRoute");
            navigate("/");
          } else window.history.back();
          break;
        case "/events/details":
        case "/events/registered":
          navigate("/events");
          break;
        case "/profile":
          if (isMobile) {
            if (leftPartShownItem === "1") window.history.back();
            else dispatch(setLeftPartItem(previousLeftPartShownItem));
          } else {
            dispatch(setLeftPartItem("1"));
            dispatch(setMiddlepartItem(null));
            dispatch(setRightPartItem(null));
          }
          break;
        case "/wallet":
          navigate("/games");
          break;
        case "/invite-referral":
        case "/login":
          navigate("/purchasing");
          break;
        case "/add-wine":
          navigate("/journal");
          break;
        case "/cart":
          if (orderTypeCookie()) navigate(-1);
          else navigate("/purchasing");
          break;
        case "/games":
          if (client?.has_played) navigate("/purchasing");
          else navigate(-1);
          break;
        default:
          return navigate(-1);
      }
  };

  const handleLogout = () => {
    dispatch(clearCartItems());
    dispatch(logOut());
  };

  const handleLedgerSearchClick = () => {
    setShowLedgerSearch(true);
    searchParams.set("search", "true");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (paymentStatusParam) {
      setShowCloseButtonCheck(true);
    }
    if (showSearchParam) {
      setShowLedgerSearch(true);
    } else setShowLedgerSearch(false);
  }, [paymentStatusParam, showSearchParam]);

  return (
    <div
      className={styles.header}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {isMainPageCheck ? (
        window.location.pathname === "/profile" && leftPartShownItem !== "1" ? (
          <div
            className={styles.leftPart}
            onClick={() => {
              window.location.pathname === "/cart"
                ? navigate("/purchasing")
                : handleBackButton(window.location.pathname);
            }}
          >
            <BackButtonIcon color={getDefaultColour(defaultColour)} />
          </div>
        ) : (
          <div
            className={styles.leftPart}
            style={
              isMobileMenuOpen ? { opacity: 0, pointerEvents: "none" } : {}
            }
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <HamburgerIcon />
          </div>
        )
      ) : showCloseButtonCheck ? (
        <div
          className={styles.closeButton}
          onClick={() => navigate("/purchasing")}
        >
          <CloseFirstTypeIcon color={getDefaultColour(defaultColour)} />
        </div>
      ) : (
        <div
          className={styles.leftPart}
          onClick={() => handleBackButton(window.location.pathname)}
        >
          <BackButtonIcon color={getDefaultColour(defaultColour)} />
          {showPageName ? (
            <span>{translations(`label.${getPageName(pathname)}`)}</span>
          ) : null}
        </div>
      )}

      <div className={styles.rightPart}>
        {isLoggedIn() &&
        [
          "/purchasing",
          "/investments",
          "/membership",
          "/games/info",
          "/games/question",
          "/games/fill-in-the-blank",
          "/games/true-or-false",
          "/games/audio-questions",
          "/games/pour-and-score",
          "/games/magic-match",
          "/games/match-the-pairs",
        ].includes(pathname) &&
        !isMobileMenuOpen ? (
          <>
            <div>
              <span
                className="reg-400"
                style={{
                  fontSize: 10,
                }}
              >
                {pathname.includes("/purchasing")
                  ? translations(
                      getTotalPrice(cartItems) !== 0
                        ? "label.in_basket"
                        : pending_delivery_balance === 0
                        ? "label.available"
                        : "label.pending_delivery"
                    )
                  : null}
                {["/games", "/games/info"].includes(pathname)
                  ? translations("label.medals")
                  : null}
                {pathname.includes("/investments")
                  ? translations("label.staked")
                  : null}
                {/* {pathname.includes("/membership")
                  ? translations("label.status")
                  : null} */}
              </span>
            </div>

            <div>
              <span
                className="reg-400"
                style={{
                  fontSize: 20,
                }}
              >
                {pathname.includes("/purchasing")
                  ? `CHF ` + availableBalance
                  : null}
                {[
                  "/games",
                  "/games/info",
                  "/games/question",
                  "/games/fill-in-the-blank",
                  "/games/true-or-false",
                  "/games/audio-questions",
                  "/games/pour-and-score",
                  "/games/magic-match",
                  "/games/match-the-pairs",
                ].includes(pathname)
                  ? medalsValue
                  : null}
                {pathname.includes("/investments")
                  ? `${getCurrencyStr(client?.currency)} ` + stakedValue
                  : null}
                {/* {pathname.includes("/membership")
                  ? client?.membership?.name
                  : null} */}
              </span>
            </div>

            {pathname.includes("/membership") ? (
              <div
                className={styles.learnMore}
                onClick={() => navigate("/membership/how-it-works")}
              >
                <div>
                  <span>{translations("label.learn_how_it_works")}</span>
                </div>
                <div>
                  <HowItWorksIcon />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        {!isLoggedIn() &&
        !isMobileMenuOpen &&
        !showPageName &&
        !window.location.pathname.includes("/forgot-passcode/email-sent") ? (
          <div
            className={styles.icon}
            onClick={() =>
              navigate("/login", { state: { lastRoute: pathname } })
            }
          >
            <LoginIcon color={"#000"} />
          </div>
        ) : null}

        {isLoggedIn() &&
        window.location.pathname.includes("/profile") &&
        !isMobileMenuOpen &&
        leftPartShownItem === "1" &&
        !showPageName ? (
          <div className={styles.icon} onClick={() => handleLogout()}>
            <LogoutIcon color={"#000"} />
          </div>
        ) : null}

        {isLoggedIn() &&
        ["/home"].includes(window.location.pathname) &&
        !isMobileMenuOpen &&
        !showPageName ? (
          <div
            className={styles.icon}
            onClick={() => navigate("/home/filters")}
          >
            <FiltersIcon color="#000" />
          </div>
        ) : null}

        {window.location.pathname === "/journal" &&
        !showLedgerSearch &&
        !isMobileMenuOpen ? (
          <div
            className={styles.searchIconLedger}
            onClick={() => handleLedgerSearchClick()}
          >
            <JournalSearchIcon />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MobileHeader;
