import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./OrderSummary.module.scss";

import {
  capitalizeFirstLetter,
  checkStringForNumbers,
  formatPrice,
  getDefaultColour,
  hasNumbers,
} from "utils/utils";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import dayjs from "dayjs";
import ButtonComponent from "components/Button/Button.component";
import { useEffect } from "react";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import { getGuestLastOrderBegin, getLastOrderBegin } from "../actions";
import { getClientBegin } from "pages/Profile/actions";

interface Props {}

function OrderSummary(props: Props) {
  const { countries, isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    lastOrder,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();
  const guestEmailParam = searchParams.get("guestEmail");
  const orderDataStored: any = lastOrder?.orderDetails;
  const orderStored: any = lastOrder;
  const deliveryPrice = !orderStored?.is_shipment_free
    ? orderStored?.deliveryMethod?.delivery_method_price
    : 0;
  const guestDeliveryCountry = countries.filter(
    (item) => item.id === orderStored?.guestUser?.da_country
  );
  const guestBillingCountry = countries.filter(
    (item) => item.id === orderStored?.guestUser?.billing_country
  );
  // const totalPriceWithoutDelivery =
  //   Number(orderStored?.total_order_amount) - Number(deliveryPrice) >= 149 &&
  //   orderStored?.payment_method_type === 1
  //     ? Number(orderStored?.total_order_amount).toFixed(2)
  //     : Number(orderStored?.total_order_amount - Number(deliveryPrice)).toFixed(
  //         2
  //       );
  const paymentType = capitalizeFirstLetter(
    orderStored?.payment_type ?? translations("label.credit_card")
  );

  const totalAmount = formatPrice(
    Number(
      lastOrder?.is_winner
        ? lastOrder?.winnerDiscount >
          Number(orderStored?.total_order_amount_discounted) +
            Number(deliveryPrice)
          ? 0.0
          : Number(orderStored?.total_order_amount_discounted) +
            Number(deliveryPrice) -
            100
        : Number(orderStored?.total_order_amount_discounted) +
            Number(deliveryPrice)
    ).toFixed(2)
  );

  const handleNext = () => {
    searchParams.delete("token");
    setSearchParams(searchParams);

    if (guestEmailParam) {
      navigate(`/guest-signup?email=${guestEmailParam}`);
    } else {
      if (lastOrder?.firstOrder) {
        navigate("/bonus/first-purchase");
      } else {
        localStorage.removeItem("orderData");
        navigate("/purchasing");
      }
    }
  };

  const getPaymentType = () => {
    switch (orderStored?.payment_type) {
      case "card":
        return translations("label.credit_card");
        break;
      case "paypal":
        return "PayPal";
        break;
      case "twint":
        return "TWINT";
        break;
      default:
        return translations("label.credit_card");
        break;
    }
  };

  useEffect(() => {
    if (!lastOrder && !guestEmailParam) {
      dispatch(getLastOrderBegin());
    }
    // return () => {
    //   localStorage.removeItem("orderData");
    //   StorageService.deleteCookie("deliveryPrice");
    // };
  }, []);

  useEffect(() => {
    if (guestEmailParam) {
      dispatch(getGuestLastOrderBegin(decodeURI(guestEmailParam)));
    }
  }, [guestEmailParam]);

  useEffect(() => {
    if (isLoggedIn && !client) {
      dispatch(getClientBegin());
    }
  }, [client, isLoggedIn]);

  return (
    <div
      className={styles.orderSummaryWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <span>{translations("label.order_summary")}</span>
      </div>

      {isMobile ? (
        <div className={styles.mobileHeader}>
          <h3>{translations("label.ordered_items")}</h3>
        </div>
      ) : null}
      {orderDataStored?.map((item, index) => {
        return isMobile ? (
          <div key={index} className={styles.mobileCard}>
            <div className={styles.topPart}>
              <div className={styles.productName}>
                {item?.necktie_id ? (
                  <span
                    style={{
                      fontFamily: "Montserrat-SemiBold",
                    }}
                  >
                    {translations("label.necktie")}
                    <br />
                  </span>
                ) : null}
                <span>
                  {item?.necktie_id
                    ? item?.necktie?.image_text?.length > 45
                      ? item?.necktie?.image_text?.substring(0, 45) + "..."
                      : item?.necktie?.image_text
                    : item?.product?.product_name?.length > 45
                    ? item?.product?.product_name?.substring(0, 45) + "..."
                    : item?.product?.product_name}
                </span>
              </div>
              <div className={styles.qty}>
                <div>
                  <span>{orderDataStored[index]?.quantity}x</span>
                </div>
                <div>
                  <span>CHF {item?.unit_price}</span>
                </div>
              </div>
            </div>
            <div className={styles.bottomPart}>
              <div>
                <span>{translations("label.total")}</span>
              </div>
              <div>
                <span>
                  CHF {formatPrice(Number(item?.total_price).toFixed(2))}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={index}
            className={styles.card}
            style={{
              borderBottom:
                index === orderDataStored?.length - 1
                  ? "none"
                  : "1px solid #dedede",
            }}
          >
            <div
              className={styles.image}
              style={
                item?.necktie_id
                  ? {
                      backgroundColor: getDefaultColour(defaultColour),
                      borderRadius: 12,
                    }
                  : {}
              }
            >
              <img
                src={
                  item?.necktie_id
                    ? item?.necktie?.image_url
                    : item?.product?.product_image
                }
                alt={
                  item?.necktie_id
                    ? item?.necktie?.image_url
                    : item?.product?.product_image
                }
              />
            </div>
            <div className={styles.leftPart}>
              <div className={styles.coloredLabel}>
                {item?.necktie_id ? null : (
                  <span>{translations("label.ordered_item")}</span>
                )}
              </div>
              <div>
                {item?.necktie_id ? (
                  <span
                    style={{
                      marginLeft: 10,
                      fontFamily: "Montserrat-SemiBold",
                    }}
                  >
                    {translations("label.necktie")}
                    <br />
                  </span>
                ) : null}
                <span
                  className={styles.productName}
                  style={item?.necktie_id ? { marginLeft: 10 } : {}}
                >
                  {!isMobile && item?.product?.product_name?.length > 45
                    ? item?.product?.product_name?.substring(0, 45) + "..."
                    : item?.necktie_id
                    ? item?.necktie?.image_text?.length > 45
                      ? item?.necktie?.image_text?.substring(0, 45) + "..."
                      : item?.necktie?.image_text
                    : item?.product?.product_name}
                </span>
              </div>
            </div>
            <div className={styles.rightPart}>
              <>
                <div>
                  <div className={styles.coloredLabel}>
                    <span>{translations("label.unit_price")}</span>
                  </div>
                  <div>
                    <span>CHF {item?.unit_price}</span>
                  </div>
                </div>
                <div>
                  <div className={styles.coloredLabel}>
                    <span>{translations("label.quantity")}</span>
                  </div>
                  <div>
                    <span>{orderDataStored[index]?.quantity}x</span>
                  </div>
                </div>
                <div>
                  <div className={styles.coloredLabel}>
                    <span>{translations("label.total_price")}</span>
                  </div>
                  <div>
                    <span>
                      CHF {formatPrice(Number(item?.total_price).toFixed(2))}
                    </span>
                  </div>
                </div>
              </>

              <div></div>
            </div>
          </div>
        );
      })}

      <div className={styles.borderedBox}>
        <div className={styles.borderedLabel}>
          <span>
            {translations("label.subtotal_including_vat")}: CHF{" "}
            {formatPrice(
              Number(orderStored?.total_order_amount)
                .toFixed(2)
                .toString()
            )}
          </span>
        </div>

        <div className={styles.borderedLabel}>
          <span>
            {translations("label.delivery_cost")}:{" "}
            {deliveryPrice
              ? `CHF ${Number(deliveryPrice).toFixed(2)}`
              : translations("label.free")}
          </span>
        </div>

        {/* <div className={styles.borderedLabel}>
        {translations("label.delivery_including_vat")}:{" "}
        {deliveryPrice
          ? `CHF ${Number(deliveryPrice).toFixed(2)}`
          : translations("label.free")}
      </div> */}

        <div className={styles.borderedLabel}>
          <span>
            {translations("label.membership_discount")} (
            {client?.permanentDiscountPercentage
              ? client?.permanentDiscountPercentage
              : 0}
            %):{" "}
          </span>
          <span>
            CHF -
            {lastOrder?.flatDiscount
              ? formatPrice(lastOrder?.flatDiscount?.toFixed(2).toString())
              : Number(0).toFixed(2)}
          </span>
        </div>

        {!guestEmailParam ? (
          <div className={styles.borderedLabel}>
            <span>{translations("label.discount_gift")}: </span>
            <span>
              CHF -
              {formatPrice(lastOrder?.winnerDiscount?.toFixed(2).toString())}
            </span>
          </div>
        ) : null}

        {!guestEmailParam ? (
          <div className={styles.borderedLabel}>
            <span>{translations("label.available_credits")}: </span>
            <span>
              CHF -
              {formatPrice(
                lastOrder?.avaliable_balance_used?.toFixed(2).toString()
              )}
            </span>
          </div>
        ) : null}

        <div className={styles.borderedLabel}>
          <span>{translations("label.tax_included_label")}: </span>
          <span>
            CHF{" "}
            {lastOrder?.tax_amount
              ? formatPrice(lastOrder?.tax_amount?.toFixed(2).toString())
              : Number(0).toFixed(2)}
          </span>
        </div>
      </div>

      <div className={styles.totalAmount}>
        <span>
          {translations("label.total_amount")}
          {": "}
        </span>
        <span className={styles.bold}>CHF {totalAmount}</span>
      </div>

      <div className={styles.orderDetails}>
        <div className={styles.leftPart}>
          <div className={styles.colored}>
            <span>{translations("label.delivery_type")}</span>
          </div>
          <div className={styles.deliveryInfo}>
            <span>
              {lastOrder?.payment_method_type === 1
                ? `${translations("label.standard_shipment.hint")}`
                : null}

              {lastOrder?.payment_method_type === 2
                ? `${translations("label.express_shipment.hint")}`
                : null}
              {lastOrder?.payment_method_type === 4
                ? `${translations("label.express_shipment_to")} ${
                    client?.da_zip || orderStored?.guestUser?.da_zip + " "
                  } ${
                    checkStringForNumbers(lastOrder?.shipment_time)
                      ? translations("label.at") +
                        " " +
                        lastOrder?.shipment_time
                      : "" +
                        " " +
                        checkStringForNumbers(lastOrder?.shipment_time)
                      ? !hasNumbers(lastOrder?.shipment_time)
                        ? String(
                            translations(lastOrder?.shipment_time)
                          ).toLowerCase()
                        : lastOrder?.shipment_time
                      : translations("label.within_next_2_hours")
                  }`
                : null}

              {lastOrder?.payment_method_type === 5
                ? `${translations("label.pickup_at_store")}`
                : null}
            </span>

            {lastOrder?.payment_method_type === 5 ? (
              <span style={{ marginTop: 10 }}>
                Wintsch & Partner GmbH
                <br />
                Aathalstrasse 84a <br /> 8610 Uster
              </span>
            ) : null}
          </div>
          <div className={styles.colored}>
            <span>
              {translations(
                lastOrder?.payment_method_type === 5
                  ? "label.billing_address"
                  : "label.delivery_address"
              )}
            </span>
          </div>
          {lastOrder?.payment_method_type === 5 ? (
            <>
              {" "}
              <div>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.billing_name
                    : client?.billing_name}
                </span>
              </div>
              <div>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.billing_company_name
                    : client?.billing_company_name}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.billing_street
                    : client?.billing_street}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.billing_city
                    : client?.billing_city}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.billing_zip
                    : client?.billing_zip}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? guestBillingCountry[0]?.name
                    : client?.countryBilling?.[`name_${client?.language}`]}
                </span>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.delivery_name
                    : client?.delivery_name}
                </span>
              </div>
              <div>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.delivery_company_name
                    : client?.delivery_company_name}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.da_street
                    : client?.da_street}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.da_city
                    : client?.da_city}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? orderStored?.guestUser?.da_zip
                    : client?.da_zip}
                </span>
              </div>
              <div className={styles.dataRow}>
                <span>
                  {orderStored?.guest_user_id
                    ? guestDeliveryCountry[0]?.name
                    : client?.countryDelivery?.[`name_${client?.language}`]}
                </span>
              </div>
            </>
          )}
        </div>
        <div className={styles.rightPart}>
          <div className={styles.colored}>
            <span>{translations("label.payment_details")}</span>
          </div>
          <div>
            <div>
              <span>{translations("label.payment_type")}</span>
            </div>
            <div>
              <span>{getPaymentType()}</span>
            </div>
          </div>
          <div>
            <div>
              <span>{translations("label.payment_date")}</span>
            </div>
            <div className={styles.date}>
              <span>
                {dayjs(orderDataStored?.order_date).format("DD.MM.YYYY HH:mm")}
              </span>
            </div>
          </div>
          {orderStored?.guest_user_id ? (
            <div>
              <div>
                <span>{translations("label.email")}</span>
              </div>
              <div>
                <span>{orderStored?.guestUser?.email}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className={styles.btn}>
        <ButtonComponent
          label={translations("button.next")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() => handleNext()}
        />{" "}
      </div>

      {!isMobile ? (
        <div className={styles.emailSentLabel}>
          <span>{translations("label.order_success.email_sent")}</span>
        </div>
      ) : null}
    </div>
  );
}

export default OrderSummary;
